import React from "react";

const Popup = props => {
  return (
    <div className="popup-box dark-mode">
      <div className="box dark-mode">
        <span className="close-icon  dark-mode" onClick={props.handleClose}>x</span>
        {props.content}
      </div>
    </div>
  );
};

export default Popup;