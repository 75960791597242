/*-- 
// TODO: 
	- Clean up code
	- Saving indicator - needs styling

	- Connection Indicator
	- Add a confirmation dialog on Clear button
	- Show connection details in header
	- Help menu for set-up
	- Add a "Copy to Clipboard" button in Preview
	- Add header component
	- Add doc list component
	


--*/

import { useState } from 'react';

// Import the required modules
import MarkdownIt from 'markdown-it';
// import FileSaver from 'file-saver';

import './App.css';
import codaIcon from './/coda.png';
import darkModeIcon from './/darkModeToggle.png';
import loading from './/Bars-1s-200px.svg';
import Popup from './Popup';

const Spinner = () => (
	<img src={loading} alt="working" height="40"/>
  )
function App() {

// Create the states that will be used
// through the application
// This state will be used for the Markdown text
let [markdownText, setMarkDownText] = useState("");

// This state will be used for the rendered HTML
let [renderedHTML, setRenderedHTML] = useState("");
let [fileName, setFileName] = useState("");
let [docId, setDocId] = useState(getParameterByName('docId'));
let [tableId, setTableId] = useState(getParameterByName('tableId'));
let [authToken, setAuthToken] = useState(getParameterByName('authToken'));
let [isSaving, setIsSaving] = useState(false);
// Set the default values for the URL parameters
if (docId === null) {
	docId = "";
}
if (tableId === null) {
	tableId = "";
}
if (authToken === null) {
	authToken = "";
}

let placeholderTextArea = authToken !== "" && authToken !== null ? "Enter your note here..." : "Please click Help for instructions on how to set up your Coda Notebook.";

// function to update authToken, docId, tableId
function updateURLParams(token, doc, table) {
	let urlParams = new URLSearchParams(window.location.search);
	urlParams.set('authToken', token);
	if (doc.length > 20) {
		urlParams.set('docId', doc.split("_d")[1].split("/")[0]);
		console.log(doc.split("_d")[1].split("/")[0]);
	} else {
		urlParams.set('docId', doc);
	}
	urlParams.set('tableId', table);
	window.history.pushState({}, '', '?' + urlParams.toString());
}

function getParameterByName(name) {
	let urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(name);
}

// let authToken = 'd2f8bb85-002f-42fc-8bc9-7a6ae7866914';
// let docId = 'V-rc5QB_xO';
// let tableId = 'grid-Rl1ByW4mnU';

// This state will be used for the filename while saving
// let fileName = useState("untitled-note");
let dateTimeName = 'Coda Note - ' + new Date().toLocaleString();
let notes = [];

(function() {
	if(notes.length === 0) {
		getListOfNotes().then(function(response) {
			getNotes()
		});
	}
})();

// Create a function that will be invoked
// whenever the user modifies the content
// in the textarea
function handleTextInput(e) {
	if (authToken !== null && authToken !== "") {
		// Change the text of the markdown side
		setMarkDownText(e.target.value);

		// Initialize a MarkdownIt instance
		let md = new MarkdownIt();

		// Render out the markdown to HTML using
		// the render() method
		let renderedHTML = md.render(e.target.value);

		// Change the markdown's side to the rendered HTML
		setRenderedHTML(renderedHTML);
	}
}

function saveToCoda() {
    console.log('saveToCoda');
	handleSetIsSaving(true);
    // Insert table row with Coda API POST request
    // https://developer.coda.io/docs/api/post-request
    fetch ("https://coda.io/apis/v1/docs/"+docId+"/tables/"+tableId+"/rows", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authToken
        },
        body: JSON.stringify({
            "rows": [{
            	"cells": [{
            			"column": "c-95daun1-sl",
            			"value": fileName === "" ? dateTimeName : fileName 
            		},
            		{
            			"column": "c-_OGzlw2YB4",
            			"value": markdownText
            		}
            	]
            }]

        })
    })
	
	.then(response => {
		if(response && response.status === 202) {
			handleSetIsSaving(false);
			document.getElementById("saveMessage").innerHTML = "Saved to Coda Table";
			setTimeout(function(){
				document.getElementById("saveMessage").innerHTML = '';
			}, 3000);
		} else {
			errorMessage(response.status);
			handleSetIsSaving(false);
			document.getElementById("errorMessage").innerHTML = `${response.status} : ${response.statusText} - Please check your configurations and try again.`;
			setTimeout(function(){
				document.getElementById("errorMessage").innerHTML = '';
			}, 10000);
		}
	})

	setMarkDownText("");
	setRenderedHTML("");
	setFileName("");
	getNotes();
}

function handleSetIsSaving(value) {
	new Promise(r => setTimeout(r, 2000));
	setIsSaving(value);
}

// Iterate over notes and create a list of them
function getNotes() {
	// Get the notes from the Coda API
	// https://developer.coda.io/docs/api/get-request
	getListOfNotes();
	let notesListHTML = "<br/>";
	console.log(`notesList: ${notes}`);
	if (notes.length > 0) {
		for (let key in notes) {
			console.log(key + " = " + JSON.stringify(notes[key]));
			// notesListHTML += `<li class="list-group-item"><a href="${notes[key].browserLink}">${notes[key].name}</a></li>`;
			notesListHTML += `<details>
			<summary><a href="${notes[key].browserLink}">${notes[key].name}</a></summary>`;
			let ind = 0;
			for (let key2 in notes[key].values) {
				if (ind === 0) {
					ind++; 
					continue;
				}
				notesListHTML += `${notes[key].values[key2]}`;
			}
		 	notesListHTML += `</details>`;
		}
			
		document.getElementById("noteList").innerHTML = notesListHTML;
	}
}


// Retrieve all of user's documents from Coda API
// https://developer.coda.io/docs/api/get-request
async function getListOfNotes() {
	await fetch ("https://coda.io/apis/v1/docs/"+docId+"/tables/"+tableId+"/rows", {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + authToken
		}
	}).then(response => response.json())
	.then(data => {
		console.log(`data: ${data.items}`);
		for(let i = 0; i < data.items.length; i++) {
			// console.log(data.items[i]);
			notes.push(data.items[i]);
		}
	});
}



function errorMessage(message) {
	alert(`Error: ${message}`);
}

function clearText() {
    setMarkDownText("");
    setRenderedHTML("");
    document.getElementById("markdown-editor").value = "";
}

function toggleDarkMode() {
		document.body.classList.toggle('dark-mode');
		document.getElementById('markdown-body').classList.toggle('dark-mode');
		document.getElementById('markdown-editor').classList.toggle('dark-mode');
		document.getElementById('markdown-output').classList.toggle('dark-mode');
		document.getElementById('file-name-box').classList.toggle('dark-mode');
		console.log(`Dark mode is ${document.body.classList.contains('dark-mode') ? 'on' : 'off'}`);
}


const [isOpen, setIsOpen] = useState(false);
 
const togglePopup = () => {
	setIsOpen(!isOpen);
}

function handleSetToken(e) {
	setAuthToken(e.target.value);
}

function handleSetDocId(e) {
	setDocId(e.target.value);
}

function handleSetTableId(e) {
	setTableId(e.target.value);
}

// function to check connection to coda api
// function checkConnection() {
// 	fetch ("https://coda.io/apis/v1/docs/"+docId+"/tables/"+tableId+"/rows", {
// 		method: 'GET',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			'Authorization': 'Bearer ' + authToken
// 		}
// 	})
// 	.then(response => {
// 		if(response && response.status === 202) {
// 			alert("Connection to Coda API successful!");
// 		} else {
// 			alert("Connection to Coda API failed!");
// 		}
// 	})
// }


return (
	<div className="container">
		<div className="top-bar">
			<button className="clearIcon" onClick={toggleDarkMode}><img src={darkModeIcon} alt="Toggle Dark Mode" height="30"/></button>
			<button
				className="clearIcon helpButton"
				type="button"
				onClick={togglePopup}
				> Help </button>
			{/* <button className="btn" type="button" id="toggle-output-button" onClick={toggleOutput}>Preview Styling</button> */}
			<input
				type="text"
				className="form-control-title dark-mode"
				placeholder="Note name (Optional)"
				aria-label="File name"
				id="file-name-box"
				value={fileName}
				onChange={fname => setFileName(fname.target.value)}/>
		</div>
	<div className="row">
		<div className="col">
			<div id="saveMessage" className="alert-success" role="alert">
			</div>	
			<div id="errorMessage" className="alert-danger" role="alert">
			</div>
		{/* Card for the markdown editor */}
		<div id="markdown-body" className="dark-mode">
			<div className="card-body">

			{/* Textarea for the markdown editor */}
			<textarea
                id="markdown-editor"
				className="form-control markdown-textarea dark-mode"
				rows={20}
                placeholder={placeholderTextArea}
				value={markdownText}
				onChange={handleTextInput}
			></textarea>
			</div>
		</div>

		{/* Card for the markdown editor */}
		<div className="col hidden" id="markdown-output-div">
			{/* Textarea for the markdown editor */}
			<div className="card-body">
			<div

				// Change the HTML to be displayed according
				// to the render produced by MarkdownIt
                id="markdown-output"
				dangerouslySetInnerHTML={{ __html: renderedHTML }}
				className="rendered-html-output"
			>
			</div>
			</div>
		</div>
		</div>
	</div>

	<div className="row">
		<div className="col col-sm-12">

		{/* Card for the save files option */}
			<div className="input-group">
				{/* Buttons for saving the text */}
				<div className="input-group-append">
                
                <button type="button"
                        className="btn btn-outline-secondary"
						disabled={!markdownText}
                        onClick={saveToCoda}>
							<img src={codaIcon} className="smallIcon" alt="Coda logo" height="25"/>  &nbsp;
							{isSaving !== false ? <Spinner /> : "Save to Coda" }
                </button>
                &nbsp; &nbsp;
                <button className="btn btn-clear"
                type="button"
				disabled={!markdownText}
                onClick={clearText}> 
                    Clear Text
                    </button>
				</div>
			</div>
			</div>
		</div>
		<div className="notesList" id="noteList"></div>

		<div>
			{isOpen && 
			<Popup content={
				<>
				<b>Help</b>
				<p></p>
				<span><b>Authorization Token: </b></span><br/>
				<input className="helpInput" type="text" placeholder="authToken" value={authToken} onChange={handleSetToken}/>
				<hr/>
				<span><b>Document ID or Document Link: </b></span><br/>
				<input className="helpInput" type="text" placeholder="docId" value={docId} onChange={handleSetDocId}/>
				<hr/>
				<span><b>Table ID or Table Name (Exact Spelling): </b></span><br/>
				<input className="helpInput" type="text" placeholder="tableId" value={tableId} onChange={handleSetTableId}/>
				<button className="invisible" onClick={updateURLParams(authToken, docId, tableId)}>Reload</button>
				<hr/>
				<h3>How to Save These Settings</h3>
				<p>Create a bookmark of this exact link. You can then access it from mobile devices and retain this connection.</p>
				<h3>How to Get Started</h3>
				<p>To get started using Coda Notes, you'll need an Authorization Token from Coda.io. The Coda API can then be accessed using an API token, which can be obtained from <a href="https://coda.io/account">My Account</a> in Coda, underneath the API SETTINGS section. </p>
				<p>Once you have your Authorization Token, you can use the form above to enter the Document ID or Document Link and Table ID or Name (exact spelling necessary, update if table name gets updated) of the document you want to use. You can then save your notes to that specific document and table in Coda.</p>
				
				<h3>How to Get Document and Table IDs</h3>
				<p>To get the Document ID a document, you can extract it from the URL - https://coda.io/d/Document-Name_d<u><b>{"<"}Document ID{">"}</b></u>/Test-Page_suLzz </p>
				<p>To get the Table ID, you can enable the Developer Mode lab in your account settings. This allows you to click the desired table and "Copy table ID". </p>
				</>
			}
		handleClose={togglePopup}
		/>}
		</div>
	</div>
)
}

export default App;
